<template>
  <div class="index-login">
    <div class="row">
      <div class="col-1 login-left"></div>

      <div class="col-11">
        <div class="login-container">
          <div class="login shadow-sm">
            <div class="mb-3">
              <!-- <img src="../../assets/logo.png" alt /> -->
              <div class="login-icon">
                <h3>
                  <em>Забравена парола</em>
                </h3>
              </div>
              <div class="mb-3">
                <label for="name">Потребителско име</label>
                <br />
                <input
                  type="text"
                  placeholder="username"
                  v-model="username"
                  @blur="$v.username.$touch"
                />
                <template v-if="$v.username.$error">
                  <div v-if="!$v.username.required" class="error-message">Username is required</div>
                </template>
                <br />
                <div class="pt-3">
                  <button
                    value="login"
                    type="submit"
                    class="btn btn-success"
                    @click.prevent="sendFromUsername(username)"
                  >Изпрати</button>
                </div>
              </div>
            </div>
            <template v-if="message">
              <p class="error-message">{{message}}</p>
            </template>
            
            <div class="pt-2">
              <router-link to="/login">Login</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1 login-right"></div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import EmailService from "../../services/email.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      username: "",
      email: "",
      loading: false,
      message: "",
    };
  },
  validations: {
    username: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  methods: {
  //   sendFromEmail(email) {
  //     EmailService.sendNewPasswordFromEmail(email)
  //       .then((response) => {
  //         this.message = response.data.message;
  //         this.$router.push("/login");
  //       })
  //       .catch(() => (this.message = "Incorrect username or password"));
  //   },
    sendFromUsername(username) {
      EmailService.sendNewPasswordFromUsername(username)
        .then((response) => {
          this.message = response.data.message;
          this.$router.push("/login");
        })
        .catch(() => (this.message = "Incorrect username or password"));
    },
  },
};
</script>

<style scouped>
.index-login {
  padding: 1rem;
}
.empty {
  padding-bottom: 10%;
}
.login-container {
  text-align: center;
  width: 50%;
  margin-left: auto;
  padding: 5rem 10rem 10rem 10rem;
}
.login {
  background-color: #f8f9fa;
  margin: 0 auto;
  border-radius: 8%;
  padding: 3rem 0;
}

.error-message {
  color: red;
}

@media (max-width: 576px) {
  .login-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 5rem 0rem 10rem 0rem;
  }
  .login {
    background-color: transparent;
  }
  .register {
    background-color: transparent;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .login-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 5rem 0rem 10rem 0rem;
  }
  .login {
    background-color: transparent;
  }
  .register {
    background-color: transparent;
    padding: 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
